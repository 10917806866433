.abschnitt {
    &__strich {
        display: block;
    }

    &:last-of-type {
        margin-bottom: 0;
    }
}

@include mediaquery(smartphone) {

    $layout: smartphone;

    .abschnitt {


        &__titel {
            @include abstand($layout, margin, alle, 30, 0, 45, 0);
            @include schriftart($layout, 35, 40, 700);
        }
        
        &__strich {
            @include abstand($layout, padding, alle, 45, 0, 0, 0);
            width: groesse($layout, 55);
            height: groesse($layout, 25 + 45);
        }
    }

}


@include mediaquery(desktop) {

    $layout: desktop;

    .abschnitt {
        @include abstand($layout, margin, alle, 0, 0, 265, 0);

        &__titel{
            @include abstand($layout, margin, alle, 52, 0, 52, 0);
            @include schriftart($layout, 52, 58, 700);
        }
        
        &__strich {
            @include abstand($layout, margin, alle, 0, 0, 52, 0);
            @include abstand($layout, padding, alle, 52, 0, 0, 0);
            width: groesse($layout, 206);
            height: groesse($layout, 73 + 52);
        }
    }

}