.rueckmeldungen {
    &__bild-img {
        border-radius: 100%;
        overflow: hidden;
        width: 100%;
    }

    &__rueckmeldung {
        break-inside: avoid;
        page-break-inside: avoid;
    }

    &__schreiben {

        body[data-site="andreasFleischlin"] & {

            &:hover {
                color: black;
                fill: black;

                .button__pfeil {
                    color: black;
                    fill: black;
                }
            }
            
        }
    }
}


@include mediaquery(smartphone) {

    $layout: smartphone;

    .rueckmeldungen {
        &__bild {
            @include abstand($layout, margin, alle, 45, 0, 45, 0);
        }

        &__rueckmeldungen {
            @include abstand($layout, margin, bottom, 45);
        }

        &__rueckmeldung {
            @include abstand($layout, margin, alle, 0, 0, 45, 0);
            @include schriftart($layout, 20, 24);
        }
    }
}


@include mediaquery(desktop) {

    $layout: desktop;

    .rueckmeldungen {
        @include abstand($layout, margin, alle, 52, -22.5, 52, -22.5);
        align-items: flex-start;
        display: flex;
        flex-wrap: wrap;
        width: calc(100% + groesse($layout, map-get($spaltenabstaende, $layout)));

        &__rueckmeldungen,
        &__bild {
            @include abstand($layout, padding, alle, 0, 22.5, 0, 22.5);
        }
        
        &__bild {
            width: calc(100% / 12 * 4);
        }
        
        &__rueckmeldungen {
            @include abstand($layout, margin, bottom, 52);
            width: calc(100% / 12 * 8);
            columns: 2;
            column-gap: groesse($layout, 45);
        }

        &__rueckmeldung {
            @include abstand($layout, margin, alle, 0, 0, 52, 0);
            @include schriftart($layout, 20, 26);
        }

        &__schreiben {
            @include abstand($layout, padding, left, 22.5);
        }
    }
}