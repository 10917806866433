.angebot {

    &__angebot {
        display: flex;
        flex-direction: column;
    }

    &__ecke {
        width: 100%;
    }

    &__inhalt {
        flex: 1;

        body[data-site="wiederInsLeben"] & {
            background-color: map-get($farben, flaechen);
        }

        body[data-site="andreasFleischlin"] & {
            background-color: map-get($farben, fleischlinHellGruen);
        }
    }

    &__titel {

        body[data-site="wiederInsLeben"] & {
            color: white;
        }

        body[data-site="andreasFleischlin"] & {
            color: black;
        }
    }

    &__details {
        body[data-site="wiederInsLeben"] & {
            color: map-get($farben, buttons);
        }
    }

    &__beschreibung {
        hyphens: auto;
        
        p:first-child,
        ul:first-child {
            margin-top: 0;
        }
        
        p:last-child,
        ul:last-child {
            margin-bottom: 0;
        }

        a {
            font-weight: 700;
            text-decoration: none;
            
            &:hover {
                color: black;
            }
        }
    }

    &__termin-anfragen {
        background-color: transparent;
        border: none;
        font-family: 'Source Serif Pro', serif;
        text-align: left;
    }
    
    &__termin-anfragen-link {
        
        body[data-site="wiederInsLeben"] & {
            color: map-get($farben, buttons);

            &:hover {
                color: black;
                fill: black;
            }
        }

        body[data-site="andreasFleischlin"] & {

            &:hover {
                color: black;
                fill: black;

                .button__pfeil {
                    color: black;
                    fill: black;
                }
            }
            
        }

        text-decoration: none;
    }
}


@include mediaquery(smartphone) {

    $layout: smartphone;

    .angebot {     
        margin-top: calc(35% + groesse($layout, 22));

        &__angebot {
            margin-top: calc(-35% + groesse($layout, 22));
        }
        
        &__inhalt {
            @include abstand($layout, padding, alle, 45, 15, 45, 15);
        }
        
        &__titel {
            @include abstand($layout, margin, alle, 0, 0, 45, 0);
            @include schriftart($layout, 36, 40, bold);
        }
        
        &__art {
            @include schriftart($layout, 20, 24, bold);
        }
        
        &__details {
            @include abstand($layout, margin, alle, 0, 0, 45, 0);
            @include schriftart($layout, 20, 24, bold);
        }
        
        &__beschreibung {
            @include abstand($layout, margin, alle, 45, 0, 0, 0);
            @include schriftart($layout, 20, 24);
            
            p {
                @include abstand($layout, margin, alle, 24, 0, 24, 0);
            }
            
            ul {
                @include abstand($layout, margin, alle, 24, 0, 24, 0);
            }

            li {
                @include abstand($layout, padding, left, 17);
                
                &::before {      
                    top: groesse($layout, -4);
                }
            }
        }
        
        &__termin-anfragen {
            @include abstand($layout, margin, alle, 45, 0, 0, 0);
        }

        &__termin-anfragen-link {
            @include schriftart($layout, 32, 34, bold);
        }

        &__pfeil {
            height: groesse($layout, 17);
            width: groesse($layout, 35);
        }
    }

}


@include mediaquery(desktop) {

    $layout: desktop;

    .angebot {
        display: flex;
        flex-wrap: wrap;
        @include abstand($layout, margin, alle, 0, -22.5, 0, -22.5);
        margin-top: calc(10.5% + groesse($layout, (165 - 45)));
        
        &__angebot {
            @include abstand($layout, padding, alle, 0, 22.5, 0, 22.5);
            margin-top: calc(-10.5% + groesse($layout, 45));
            width: calc(100% / 3 * 1);
        }
        
        &__inhalt {
            @include abstand($layout, padding, alle, 70, 20, 52, 20);
        }
        
        &__titel {
            @include abstand($layout, margin, alle, 0, 0, 52, 0);
            @include schriftart($layout, 52, 58, bold);
        }
        
        &__art {
            @include schriftart($layout, 24, 28, bold);
        }
        
        &__details {
            @include abstand($layout, margin, alle, 0, 0, 52, 0);
            @include schriftart($layout, 24, 28, bold);
        }
        
        &__beschreibung {
            @include abstand($layout, margin, alle, 52, 0, 0, 0);
            @include schriftart($layout, 20, 28);
            
            p {
                @include abstand($layout, margin, alle, 28, 0, 28, 0);
            }
            
            ul {
                @include abstand($layout, margin, alle, 28, 0, 28, 0);
            }

            li {
                @include abstand($layout, padding, left, 16);
                
                &::before {      
                    top: groesse($layout, -4);
                }
            }
        }
        
        &__termin-anfragen {
            @include abstand($layout, margin, alle, 52, 0, 0, 0);
        }

        &__termin-anfragen-link {
            @include schriftart($layout, 32, 34, bold);
        }

        &__pfeil {
            height: groesse($layout, 17);
            width: groesse($layout, 35);
        }
    }

}