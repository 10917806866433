.menue {
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    position: fixed;
    top: 0;

    &__svgs {
        display: none;
    }

    &__link {
        align-items: flex-start;
        display: block;
        transform-origin: top left;
        transform: rotate(-19deg);
        text-decoration: none;

        body[data-site="wiederInsLeben"] & {
            color: map-get($farben, flaechen);
        }

        body[data-site="andreasFleischlin"] & {
            color: black;
        }
    }

    &__link:hover {
        body[data-site="wiederInsLeben"] & {
        color: black
        }
    }

    &__kurzzugriffe {
        .body[data-site="wiederInsLeben"] & {
            display: none;
        }
    }
}

@include mediaquery(smartphone) {

    $layout: smartphone;

    body {

        &[data-menue-aktiv="true"] {
            position: fixed;
            width: 100%;
        }

    }

    .menue {
        @include abstand($layout, padding, alle, 185, 22, 22, 22);
        @include schriftart($layout, 28, 40, 700);
        background-color: white;
        left: 0;
        height: calc((var(--vh, 1vh) * 100));
        transform: translateX(100%);
        width: 100%;
        z-index: 100;

        body[data-site="andreasFleischlin"][data-menue-aktiv="true"] & {
            display: flex;
            flex-direction: column-reverse;
        }

        body[data-menue-aktiv="true"] & {
            transform: translateX(0);
        }

        &__link {

            &--1 {
                @include abstand($layout, margin, top, 30);
            }
        }

        &__hamburger {
            // +1 wegen svg padding
            @include abstand($layout, padding, alle, 16 + 1, 22 + 1, 16 + 1, 22 + 1);
            position: fixed;
            right: 0;
            top: 0;
            height: groesse($layout, 53 + (16 * 2));
            width: groesse($layout, 55 + (22 * 2));
            z-index: 101;
            cursor: pointer;
        }

        body[data-menue-aktiv="true"][data-site="andreasFleischlin"] &__svgs {
            @include abstand($layout, margin, alle, 0, -10, 0, -10);
            display: flex;
            left: spalten($layout, 0, 8, 0, 2.5);
            position: absolute;
            top: calc(0px - 140px + 17px);
            z-index: 110;
        }

        &__at {
            width: 100%;
            height: 100%;
        }

        &__at-link {
            @include abstand($layout, margin, alle, -5, 5, 0, 5);
            @include abstand($layout, padding, alle, 5, 5, 5, 5);
            width: calc(groesse($layout, 33.5) + 10px);
            height: calc(groesse($layout, 33.9) + 10px);
        }

        &__pin {
            width: 100%;
            height: 100%;
        }

        &__pin-link {
            @include abstand($layout, margin, alle, -5, 5, 0, 5);
            @include abstand($layout, padding, alle, 5, 5, 5, 5);
            width: calc(groesse($layout, 28.5) + 10px);
            height: calc(groesse($layout, 41) + 10px);
        }

        &__instagram-link {
            @include abstand($layout, margin, alle, -5, 5, 0, 5);
            @include abstand($layout, padding, alle, 5, 5, 5, 5);
            width: calc(groesse($layout, 34.75) + 10px);
            height: calc(groesse($layout, 34.8) + 10px);
        }

        &__instagram {
            width: 100%;
            height: 100%;
        }


        &__logo-wil {
            align-self: flex-end;
            height: groesse($layout, 203);
            margin-top: auto;
            width: groesse($layout, 109);
        }

        &__logo-af {
            align-self: flex-end;
            height: groesse($layout, 203);
            margin-top: auto;
            width: groesse($layout, 109);
        }

        &__logo-svg {
            height: 100%;
            width: 100%;
        }

        &__kurzzugriffe {
            display: none;
        }
    }
}


@include mediaquery(desktop) {

    $layout: desktop;

    .menue {
        @include abstand($layout, margin, top, 160);
        @include schriftart($layout, 28, 40, 700);
        right: 0;
        width: groesse($layout, 325);
        height: calc(100% - groesse($layout, 534));

        .body[data-site="andreasFleischlin"] & {
            @include abstand($layout, margin, top, 570 - 36);
        }

        &__links {
            @include abstand($layout, padding, left, 10);
        }

        &__link {

            &--1 {
                @include abstand($layout, margin, top, 36);
            }
        }

        &__hamburger {
            display: none;
        }

        &__logo-wil {
            position: fixed;
            bottom: groesse($layout, 60);
            right: groesse($layout, 80);
            height: groesse($layout, 166);
            width: groesse($layout, 98);
        }

        &__logo-af {
            position: fixed;
            top: groesse($layout, 100);
            right: groesse($layout, 109);
            height: groesse($layout, 375);
            width: groesse($layout, 201);
        }

        &__logo-svg {
            width: 100%;
            height: 100%;
        }

        &__kurzzugriffe {
            @include abstand($layout, margin, bottom, 75);
            @include abstand($layout, padding, left, 5);
            align-items: flex-end;
            height: 100%;
            margin-right: groesse($layout, 25);

            .body[data-site="andreasFleischlin"] & {
                display: flex;
            }
        }

        &__kurzzugriffe-container {
            display: flex;
        }

        &__kurzzugriff-svg {
            @include abstand($layout, margin, alle, 0, 10, 0, 10);
            height: groesse($layout, 34);
            width: groesse($layout, 34);
        }
    }

}