.rechteckige-bilder {

}


@include mediaquery(smartphone) {

    $layout: smartphone;

    .rechteckige-bilder {
        @include abstand($layout, margin, alle, 45, 0, 45, 0);
        
        &__bild-container {
            @include abstand($layout, margin, alle, 45, 0, 45, 0);
        }
        
        &__bildlegende {
            @include abstand($layout, margin, top, 5);
            @include schriftart($layout, 20, 24, 300);
        }
    }
}


@include mediaquery(desktop) {

    $layout: desktop;

    .rechteckige-bilder {
        width: spalten($layout, 6, 12, 0, 0);

        &__bild-container {
            @include abstand($layout, margin, bottom, 50);
        }

        &__bildlegende {
            @include abstand($layout, margin, top, 15);
            @include schriftart($layout, 24, 28, 400);
        }
    }
}