.intro {
    &__titel {

        body[data-site="wiederInsLeben"] & {
            color: map-get($farben, flaechen);
        }

        body[data-site="andreasFleischlin"] & {
            color: map-get($farben, fleischlinGruen);
        }
    }

    &__bilder {
        display: flex;
    }

    &__bild {

        .intro__img {
            display: block;
        }
        
        .intro__img-hover {
            display: none;
        }
    }

    &__bild[data-link-aktiv="true"]:hover {

        .intro__img {
            display: none;
        }

        .intro__img-hover {
            display: block;
        }
    }

    &__img, &__img-hover {
        border-radius: 100%;
        overflow: hidden;
        width: 100%;
    }

    &__einstieg-bild {
        border-radius: 100%;
        overflow: hidden;
    }
}


@include mediaquery(smartphone) {

    $layout: smartphone;

    .intro {
        display: flex;
        flex-direction: column;
        min-height: calc(100vh - groesse($layout, 90));

        &__titel {
            @include abstand($layout, margin, bottom, 10);
            @include schriftart($layout, 34, 34, 700);
        }

        &__untertitel {
            @include schriftart($layout, 20, 24);
        }

        &__bilder {
            @include abstand($layout, margin, alle, 45 - 7.5, -7.5, 0, -7.5);
            display: flex;
            flex-wrap: wrap;
        }

        &__bild {
            @include abstand($layout, padding, alle, 7.5, 7.5, 7.5, 7.5);
            width: calc(100% / 8 * 4);

            body[data-layout="smartphone"][data-site="andreasFleischlin"] &:nth-last-child(-n+4),
            body[data-layout="small"][data-site="andreasFleischlin"] &:nth-last-child(-n+4) {
                display: none;
            }
        }

        &__einstieg-text {
            // 88 gesammte hoehe - .intro__bilder--hauptseite (margin-bottom)
            @include abstand($layout, margin, top, 46 - 15);
            @include abstand($layout, margin, bottom, 45);
            @include schriftart($layout, 20, 24, 400);
        }

        &__einstieg-autor {
            @include abstand($layout, margin, bottom, 30);
            @include schriftart($layout, 20, 20, 700);
        }

        &__einstieg-bild {
            width: spalten($layout, 4, 10, 0, 0);
        }
        
        &__aktuell {
            display: flex;
            flex-direction: column;
        }

        &__smartphone-logo {
            @include abstand($layout, margin, alle, 8, 0, 80, 0);
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: row-reverse;
        }
        
        &__smartphone-logo-svg {
            width: groesse($layout, 55);
            height: groesse($layout, 81);
            display: flex;
        }
    }

}


@include mediaquery(desktop) {

    $layout: desktop;

    .intro {

        body[data-site="wiederInsLeben"] & {
            @include abstand($layout, margin, bottom, 265 - 52);
        }

        &__titel {
            @include abstand($layout, margin, bottom, 20);
            @include schriftart($layout, 100, 100, 700);
        }

        &__untertitel {
            @include schriftart($layout, 52, 58);
        }

        &__bilder {

            &--microsite {
                @include abstand($layout, margin, alle, 104, -22.5, 0, -22.5);
            }

            &--hauptseite {
                @include abstand($layout, margin, top, 104);
                display: flex;
                flex-wrap: wrap;
                margin-left: groesse($layout, -22.5);
                margin-right: calc(groesse($layout, -22.5) + (spalten($layout, 2, 12)));
            }
        }

        &__bild {
            @include abstand($layout, padding, alle, 0, 22.5, 0, 22.5);

            .intro__bilder--microsite & {
                width: spalten($layout, 4, 12, 0, 2);
            }

            .intro__bilder--hauptseite & {
                width: spalten($layout, 2 + calc(20 / 75), 12, 1, 2);
                @include abstand($layout, margin, bottom, 40);
            }
        }

        &__einstieg {
            width: spalten($layout, 10, 12, 0, 0);
            display: block;
        }

        &__einstieg-text {
            // 88 gesammte hoehe - .intro__bilder--hauptseite (margin-bottom)
            @include abstand($layout, margin, top, 88 - 40);
            @include abstand($layout, margin, bottom, 94);
            @include schriftart($layout, 52, 58, 400);
        }

        &__einstieg-autor {
            @include abstand($layout, margin, bottom, 52);
            @include schriftart($layout, 35, 35, 700);
        }

        &__einstieg-bild {
            width: spalten($layout, 2, 10, 0, 1);
        }

        &__aktuell {
            display: flex;
            flex-wrap: wrap;
        }
        
        &__aktuell-intro {
            @include abstand($layout, margin, alle, 245, 0, 65, 0);
            @include schriftart($layout, 52, 52, 700);
            color: map-get($farben, fleischlinGruen);
            width: 100%;
        }

        &__aktuell-spaltenabstand {
            width: groesse($layout, 80);
        }

        &__aktuell-strich {
            width: groesse($layout, 107);
            height: groesse($layout, 53.5);
        }

        &__aktuell-container {
            width: spalten($layout, 3.35, 12, 0, 1);
        }
        
        &__aktuell-datum {
            @include schriftart($layout, 24, 38, 700);
        }
        
        &__aktuell-titel {
            @include abstand($layout, margin, bottom, 5);
            @include schriftart($layout, 36, 38, 700);
        }
        
        &__aktuell-text {
            @include abstand($layout, margin, bottom, 25);
            @include schriftart($layout, 20, 26, 300);
        }

        &__smartphone-logo {
            display: none;
        }
    }

}