.main {
    position: relative;
    background-color: white;
}


@include mediaquery(smartphone) {

    $layout: smartphone;

    .main {
        @include abstand($layout, padding, alle, 90, 22, 0, 22);
        
        body[data-site="andreasFleischlin"] & {
            @include abstand($layout, padding, alle, 30, 22, 0, 22);
            @include abstand($layout, margin, alle, 140, 0, 140, 0);
            min-height: calc(100vh - groesse($layout, 140));
        }
    }
}

@include mediaquery(desktop) {
    
    $layout: desktop;

    .main {
        @include abstand($layout, padding, alle, 63, 375, (265 - 45), 200);
        @include abstand($layout, margin, alle, 25, 25, 25, 200);

        body[data-site="andreasFleischlin"] & {
            min-height: calc(100vh - groesse($layout, 25));
        }
    }
}