.footer {
    background-color: map-get($farben, 'flaechen');
    width: 100%;
}


@include mediaquery(smartphone) {

    $layout: smartphone;

    .footer {
        //-135 = scrollpfeil margin-bottom
        @include abstand($layout, margin, top, (55 - 135));
        @include abstand($layout, padding, alle, 0, 22, 55, 22);
        @include schriftart($layout, 36, 36, 700);
        color: white;

        &__br {
            display: none;
        }
    }

}


@include mediaquery(desktop) {

    $layout: desktop;

    .footer {
        @include abstand($layout, padding, alle, 153, 400, 153, 400);  
        color: white;
        @include schriftart($layout, 52, 58, 700);
    }

}