// ==========================================================================
// Variables
// ==========================================================================

// TODO
// Keep for backward compatibility. Remove Sass variables in Formie 2.0
// in favour of only CSS variables
$field-gutter: 0.5rem !default;
$row-gutter: 1.25rem !default;
$border-radius: 0 !default;

$primary-color: #2A8591 !default;
$primary-color-hover: #2A8591 !default;
$error: #f56565 !default;
$error-light: #f8b4b4 !default;
$error-dark: #771d1d !default;
$success: #00ae2a !default;
$gray-100: #F3F4F6 !default;
$gray-200: #E5E7EB !default;
$gray-300: #D1D5DB !default;
$gray-400: #9CA3AF !default;
$gray-500: #6B7280 !default;
$gray-600: #4B5563 !default;
$gray-700: #374151 !default;
$gray-800: #1F2937 !default;
$gray-900: #111827 !default;
$border-color: $gray-300 !default;

$focus-border-color: #a4cafe !default;
$focus-shadow: 0 0 0 3px rgba(164, 202, 254, 0.45) !default;

.fui-i {
    // General
    --fui-font: "Source Serif Pro", sans-serif;
    --fui-font-size: 16px;
    --fui-color: #{$gray-700};
    --fui-primary-color: #{$primary-color};
    --fui-primary-color-hover: #{$primary-color-hover};
    --fui-error: #{$error};
    --fui-success: #{$success};
    --fui-gray-100: #{$gray-100};
    --fui-gray-200: #{$gray-200};
    --fui-gray-300: #{$gray-300};
    --fui-gray-400: #{$gray-400};
    --fui-gray-500: #{$gray-500};
    --fui-gray-600: #{$gray-600};
    --fui-gray-700: #{$gray-700};
    --fui-gray-800: #{$gray-800};
    --fui-gray-900: #{$gray-900};
    --fui-border: 0 solid #{$border-color};
    --fui-border-radius: #{$border-radius};
    --fui-border-color: #{$border-color};
    --fui-focus-border-color: #{$focus-border-color};
    --fui-focus-shadow: #{$focus-shadow};

    // Layout/Grid
    --fui-row-gutter: #{$row-gutter};
    --fui-row-gutter-top: 1.5rem;
    --fui-field-gutter: #{$field-gutter};

    // Loading Spinner
    --fui-loading-min-height: 1rem;
    --fui-loading-height: 1rem;
    --fui-loading-width: 1rem;
    --fui-loading-margin-top: -0.5rem;
    --fui-loading-margin-left: -0.5rem;
    --fui-loading-border-width: 2px;
    --fui-loading-animation: loading 0.5s infinite linear;
    --fui-loading-left: 50%;
    --fui-loading-top: calc(50% - 1px);
    --fui-loading-z-index: 1;
    // --fui-loading-border-color

    // Alerts
    --fui-alert-padding: 1rem;
    --fui-alert-line-height: 1.25rem;
    --fui-alert-font-size: 0.875rem;
    --fui-alert-font-weight: 500;
    --fui-alert-margin-bottom: 1rem;
    --fui-alert-error-bg-color: #fdf2f2;
    --fui-alert-error-color: #{$error-dark};
    --fui-alert-success-bg-color: #f3faf7;
    --fui-alert-success-color: #03543f;
    // --fui-alert-border-radius

    // Button
    --fui-btn-display: inline-block;
    --fui-btn-text-align: center;
    --fui-btn-white-space: nowrap;
    --fui-btn-vertical-align: middle;
    --fui-btn-border-style: none;
    --fui-btn-text-decoration: none;
    --fui-btn-padding: 0px;
    --fui-btn-border: 0px solid transparent;
    --fui-btn-font-weight: 700;
    --fui-btn-transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
    --fui-btn-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    --fui-btn-transition-duration: 0.15s;
    --fui-btn-opacity-disabled: 0.65;
    // --fui-btn-bg-color
    // --fui-btn-bg-color-hover
    // --fui-btn-border-radius

    // Button Container
    --fui-btn-container-padding: 1rem 0 0 0;
    --fui-btn-container-margin: 0 -0.5rem 0 -0.5rem;
    --fui-btn-margin: 0 0.5rem 0 0.5rem;

    // Link
    --fui-link-transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
    --fui-link-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    --fui-link-transition-duration: 0.15s;
    --fui-link-text-decoration: underline;
    // --fui-link-color
    // --fui-link-color-hover

    // Submit Button
    --fui-submit-btn-color: #2A8591;
    --fui-submit-btn-color-hover: #2A8591;
    --fui-submit-btn-spinner-color: #2A8591;
    // --fui-submit-btn-bg-color
    // --fui-submit-btn-bg-color-hover
    // --fui-submit-btn-border-color
    // --fui-submit-btn-border-color-hover
    // --fui-submit-btn-box-shadow-focus

    // Form Titles
    --fui-title-margin: 0 0 1rem;
    --fui-title-font-size: 1.4rem;
    --fui-title-font-weight: 700;

    // Page Titles
    --fui-page-title-margin: 0 0 1rem;
    --fui-page-title-font-size: 1.125rem;
    --fui-page-title-font-weight: 600;

    // Page Tabs
    --fui-tabs-margin-bottom: 1rem;
    --fui-tabs-font-size: 0.875rem;
    --fui-tab-padding: 0.5rem 1rem;
    --fui-tab-margin-bottom: -1px;
    --fui-tab-border: 1px solid transparent;
    --fui-tab-active-font-weight: 500;
    --fui-tab-active-bg-color: #fff;
    --fui-tab-active-border-bottom-color: transparent;
    // --fui-tabs-border-bottom
    // --fui-tab-color
    // --fui-tab-active-color
    // --fui-tab-active-border-color
    // --fui-tab-active-border-radius
    // --fui-tab-error-color

    // Page Progress
    --fui-progress-height: 1.2rem;
    --fui-progress-font-size: 0.8rem;
    --fui-progress-font-weight: 500;
    --fui-progress-color: #fff;
    --fui-progress-bar-transition: width 0.3s ease;
    // --fui-progress-border-radius
    // --fui-progress-bg-color
    // --fui-progress-bar-bg-color

    // Validation
    --fui-error-font-size: 0.875rem;
    --fui-error-margin-top: 0.5rem;
    // --fui-error-color
    // --fui-required-color
    // --fui-success-color

    // Field Labels
    --fui-label-font-weight: 300;
    --fui-label-error-color: #{$error-dark};
    --fui-label-error-border-color: #{$error-light};
    // --fui-label-color

    // Field Instructions
    --fui-instructions-font-size: 0.875rem;
    --fui-instructions-line-height: 1.25;
    --fui-instructions-margin: 0.5rem;
    // --fui-instructions-color

    // Field - Checkboxes/Radios
    --fui-check-margin-right: 1rem;
    --fui-check-bg-color: #f0f1f4;
    // --fui-check-bg-color-checked
    // --fui-check-border-color-checked
    // --fui-check-border-color-focus
    // --fui-check-box-shadow-focus

    --fui-check-label-transition: all 0.15s cubic-bezier(0.4, 0, 0.2, 1);
    --fui-check-label-top: 0;
    --fui-check-label-bg-color: #fff;
    --fui-check-label-transition: all 0.15s cubic-bezier(0.4, 0, 0.2, 1);
    // --fui-check-label-border

    --fui-check-check-border-radius: 2px;
    --fui-check-check-bg-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
    --fui-check-check-bg-size: 8px auto;

    --fui-check-radio-border-radius: 50%;
    --fui-check-radio-bg-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
    --fui-check-radio-bg-size: 8px auto;

    // Field - Group
    --fui-group-padding: 1rem;
    // --fui-group-border
    // --fui-group-border-radius

    // Field - Input
    --fui-input-background-color: #fff;
    --fui-input-transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
    --fui-input-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    --fui-input-transition-duration: 0.15s;
    --fui-input-error-color: #{$error-dark};
    --fui-input-error-border-color: #{$error-light};
    --fui-input-error-box-shadow-focus: 0 0 0 3px rgba(248, 180, 180, 0.45);
    // --fui-input-placeholder-color
    // --fui-input-border
    // --fui-input-border-radius
    // --fui-input-border-color-focus
    // --fui-input-box-shadow-focus

    // Field - Repeater
    --fui-repeater-row-padding: 1rem;
    --fui-repeater-row-margin-bottom: 1rem;
    // --fui-repeater-row-border
    // --fui-repeater-row-border-radius

    --fui-repeater-add-btn-padding-left: 2rem;
    --fui-repeater-add-btn-top: 0.75rem;
    --fui-repeater-add-btn-left: 0.75rem;
    --fui-repeater-add-btn-width: 14px;
    --fui-repeater-add-btn-height: 14px;
    --fui-repeater-add-btn-bg-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 384 512'%3E%3Cpath fill='currentColor' d='M368 224H224V80c0-8.84-7.16-16-16-16h-32c-8.84 0-16 7.16-16 16v144H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h144v144c0 8.84 7.16 16 16 16h32c8.84 0 16-7.16 16-16V288h144c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16z'%3E%3C/path%3E%3C/svg%3E");

    --fui-repeater-remove-btn-top: 0;
    --fui-repeater-remove-btn-right: -14px;
    --fui-repeater-remove-btn-transform: translate(0, -50%);
    --fui-repeater-remove-btn-border-radius: 50%;
    --fui-repeater-remove-btn-height: 0;
    --fui-repeater-remove-btn-width: 0;
    --fui-repeater-remove-btn-padding: 13px;
    --fui-repeater-remove-btn-text-indent: -9999px;
    --fui-repeater-remove-btn-icon-top: 50%;
    --fui-repeater-remove-btn-icon-left: 50%;
    --fui-repeater-remove-btn-icon-width: 9px;
    --fui-repeater-remove-btn-icon-height: 14px;
    --fui-repeater-remove-btn-icon-transform: translate(-50%, -50%);
    --fui-repeater-remove-btn-icon-bg-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cpath fill='currentColor' d='M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z'%3E%3C/path%3E%3C/svg%3E");
    // --fui-repeater-remove-btn-border

    // Field - Select
    --fui-select-option-padding: 0.1rem 0.4rem;
    --fui-select-padding-right: 2rem;
    --fui-select-bg-position: right 0.25rem center;
    --fui-select-bg-size: 1.5em 1.5em;
    --fui-select-bg-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='none'%3E%3Cpath d='M7 7l3-3 3 3m0 6l-3 3-3-3' stroke='%239fa6b2' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");

    // Field - Table
    --fui-table-width: 100%;
    --fui-table-margin-bottom: 1rem;
    --fui-table-border-collapse: collapse;

    --fui-table-row-padding: 0.2rem;
    --fui-table-th-text-align: inherit;
    --fui-table-th-font-size: 0.75rem;
    --fui-table-th-font-weight: 600;
    // --fui-table-th-color

    --fui-table-add-btn-padding-left: 2rem;

    --fui-table-add-btn-top: 0.75rem;
    --fui-table-add-btn-left: 0.75rem;
    --fui-table-add-btn-width: 14px;
    --fui-table-add-btn-height: 14px;
    --fui-table-add-btn-bg-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 384 512'%3E%3Cpath fill='currentColor' d='M368 224H224V80c0-8.84-7.16-16-16-16h-32c-8.84 0-16 7.16-16 16v144H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h144v144c0 8.84 7.16 16 16 16h32c8.84 0 16-7.16 16-16V288h144c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16z'%3E%3C/path%3E%3C/svg%3E");

    --fui-table-remove-btn-border-radius: 50%;
    --fui-table-remove-btn-padding: 13px;
    --fui-table-remove-btn-text-indent: -9999px;
    --fui-table-remove-btn-top: 50%;
    --fui-table-remove-btn-left: 50%;
    --fui-table-remove-btn-width: 9px;
    --fui-table-remove-btn-height: 14px;
    --fui-table-remove-btn-transform: translate(-50%, -50%);
    --fui-table-remove-btn-bg-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cpath fill='currentColor' d='M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z'%3E%3C/path%3E%3C/svg%3E");
    // --fui-table-remove-btn-border

    // Field - Signature
    --fui-signature-width: 100%;
    --fui-signature-height: 8rem;
    --fui-signature-bg: #f9fafb;
    // --fui-signature-border
    // --fui-signature-border-radius

    --fui-signature-remove-btn-top: 0;
    --fui-signature-remove-btn-right: -14px;
    --fui-signature-remove-btn-transform: translate(0, -50%);
    --fui-signature-remove-btn-border-radius: 50%;
    --fui-signature-remove-btn-height: 0;
    --fui-signature-remove-btn-width: 0;
    --fui-signature-remove-btn-padding: 13px;
    --fui-signature-remove-btn-text-indent: -9999px;
    --fui-signature-remove-btn-icon-top: 50%;
    --fui-signature-remove-btn-icon-left: 50%;
    --fui-signature-remove-btn-icon-width: 9px;
    --fui-signature-remove-btn-icon-height: 14px;
    --fui-signature-remove-btn-icon-transform: translate(-50%, -50%);
    --fui-signature-remove-btn-icon-bg-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='far' data-icon='times' class='svg-inline--fa fa-times fa-w-10' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cpath fill='currentColor' d='M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z'%3E%3C/path%3E%3C/svg%3E");
    // --fui-signature-remove-btn-border

    // Field - Summary
    --fui-summary-padding: 1rem;
    // --fui-summary-border
    // --fui-summary-border-radius

    & input,
    & .fui-checkbox label::before,
    & .fui-radio label::before,
    & textarea {
        font-weight: 700;
        border: 1px solid black;
    }

    & .fui-label {
        color: black;
    }

    .iti__selected-dial-code {
        font-weight: 700;
    }

    .fui-btn {
        background-color: transparent;

        &:hover {
            background-color: transparent;
        }
    }

    .fui-row {
        display: block;
    }
}


.formie-recaptcha-placeholder {
    display: flex;
    flex-direction: row-reverse;
}




@include mediaquery(smartphone) {

    $layout: smartphone;

    .fui-i {
        // Field Labels
        --fui-label-font-size: #{groesse($layout, 20)};
        --fui-label-line-height: #{groesse($layout, 28)};
        --fui-label-margin: #{groesse($layout, 0)};

        // Input
        --fui-input-font-size: #{groesse($layout, 20)};
        --fui-input-line-height: #{groesse($layout, 20)};
        --fui-input-width: 100%;
        --fui-input-padding: #{groesse($layout, 8)};

        // Button
        --fui-btn-font-size: #{groesse($layout, 28)};
        --fui-btn-line-height: #{groesse($layout, 28)};

        // Field - Checkboxes/Radios
        --fui-check-font-size: #{groesse($layout, 20)};
        --fui-check-line-height: #{groesse($layout, 20)};
        --fui-check-label-width:  #{groesse($layout, 40)};
        --fui-check-label-height:  #{groesse($layout, 40)};
        --fui-check-label-padding-left: #{groesse($layout, (40 + 15))};
        --fui-check-label-line-height: #{groesse($layout, 40)};

        .iti__selected-dial-code {
            line-height: groesse($layout, 24);
            font-size: groesse($layout, 24);
        }

        .fui-type-checkboxes[data-field-handle="angebote"] {

            .fui-input-container {
                @include abstand($layout, margin, alle, 30, 0, 30, 0);
            }
        }

        .fui-input {
            font-size: groesse($layout, 24);
        }
    }
}

@include mediaquery(desktop) {

    $layout: desktop;

    .fui-i {
        // Field Labels
        --fui-label-font-size: #{groesse($layout, 20)};
        --fui-label-line-height: #{groesse($layout, 28)};
        --fui-label-margin: #{groesse($layout, 0)};

        // Input
        --fui-input-font-size: #{groesse($layout, 24)};
        --fui-input-line-height: #{groesse($layout, 24)};
        --fui-input-width: 100%;
        --fui-input-padding: #{groesse($layout, 10)};

        // Button
        --fui-btn-font-size: #{groesse($layout, 32)};
        --fui-btn-line-height: #{groesse($layout, 32)};

        // Field - Checkboxes/Radios
        --fui-check-margin-bottom: #{groesse($layout, 10)};
        --fui-check-font-size: #{groesse($layout, 20)};
        --fui-check-line-height: #{groesse($layout, 20)};
        --fui-check-label-width:  #{groesse($layout, 40)};
        --fui-check-label-height:  #{groesse($layout, 40)};
        --fui-check-label-padding-left: #{groesse($layout, (40 + 15))};
        --fui-check-label-line-height: #{groesse($layout, 40)};

        .iti__selected-dial-code {
            line-height: groesse($layout, 24);
            font-size: groesse($layout, 24);
        }

        .fui-type-checkboxes[data-field-handle="angebote"] {

            .fui-input-container {
                @include abstand($layout, margin, top, 30);
                columns: 2;
            }
        }
    }
}