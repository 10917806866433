.kontaktformular {

    &__label {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    &__input {
        width: 100%;
        border-radius: 0;
        border: 1px solid black;
        font-family: 'Source Serif Pro', serif;
    }

    &__checkbox-label {
        align-items: center;
        cursor: pointer;
        display: flex;
    }

    &__checkbox {
        accent-color: map-get($farben, 'titel');
        border-radius: 0;
        border: 1px solid black;
        cursor: pointer;
    }

    &__label {
        cursor: pointer;
    }

    &__bestaetigung {
        background-color: white;
        height: 100vh;
        left: 0;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 1000;
        
        &[data-bestaetigt="false"] {
            visibility: hidden;
            opacity: 0;
            transition: visibility 0.2s, opacity 0.2s linear;
        }
        
        &[data-bestaetigt="true"] {
            visibility: visible;
            opacity: 1;
            transition: visibility 0s, opacity 0.2s linear;
        }
    }

    &__bestaetigung-schliessen {
        border: none;
        background-color: white;
        cursor: pointer;
        position: absolute;
    }

    body[data-site="wiederInsLeben"] & .fui-type-multi-line-text {
        display: none;
    }

    body[data-site="andreasFleischlin"] & .fui-type-checkboxes[data-field-handle="angebote"] {
        display: none;
    }

    .fui-checkbox input:checked + label::before {
        background-image: url('/bausteine/kontaktformular/checkbox.svg');
        background-size: 100%;
    }

    .fui-checkbox input:checked + label::before, .fui-radio input:checked + label::before {
        border-color: black;
        background-color: transparent;
    }

    body[data-site="wiederInsLeben"] & .fui-submit {
        background-image: url('/bausteine/kontaktformular/pfeil-blau.svg');
        background-repeat: no-repeat;
        background-position: right center;
    }

    body[data-site="andreasFleischlin"] & .fui-submit {
        background-image: url('/bausteine/kontaktformular/pfeil-gruen.svg');
        background-repeat: no-repeat;
        background-position: right center;
    }
}

@include mediaquery(smartphone) {

    $layout: smartphone;

    .kontaktformular {
        
        &__label {
            @include schriftart($layout, 20, 28);
        }
        
        &__abschnitt {
            @include abstand($layout, margin, alle, 0, 0, 20, 0);
            @include schriftart($layout, 20, 28);
        }

        &__angebote {
            @include abstand($layout, padding, alle, 0, 0, 45, 0);
            display: flex;
            flex-direction: column;
        }

        &__checkbox {
            @include abstand($layout, margin, alle, 0, 10, 0, 0);
            height: groesse($layout, 30);
            width: groesse($layout, 30);
        }
        
        &__checkbox-label {
            @include schriftart($layout, 20, 28);
            padding-top: groesse($layout, 10);
        }

        &__kontaktfelder {
            @include abstand($layout, margin, bottom, 45);
        }

        &__feld {
            @include abstand($layout, margin, alle, 10, 0, 10, 0);
        }

        &__input {
            @include schriftart($layout, 24, 28, 700);
            @include abstand($layout, padding, alle, 0, 10, 0, 10);
            height: groesse($layout, 42);
        }

        .fui-type-checkboxes[data-field-handle="newsletter"] {
            @include abstand($layout, margin, top, 52);
        }
        
        &__bestaetigung {
            @include abstand($layout, padding, alle, 170, 22, 0, 22);
            
            p {
                @include schriftart($layout, 20, 24);
                @include abstand($layout, margin, alle, 24, 0, 24, 0);
            }
        }

        &__bestaetigung-schliessen {
            right: groesse($layout, 22);
            top: groesse($layout, 26);
        }
        
        &__bestaetigung-schliessen-svg {
            height: groesse($layout, 30);
            width: groesse($layout, 30);
        }

        .fui-submit {
            padding-right: groesse($layout, 40);
            background-size: groesse($layout, 24.35), auto;
        }

    }
}


@include mediaquery(desktop) {
    $layout: desktop;

    .kontaktformular {
        width: spalten($layout, 6, 12, 0, 0);
        
        &__label {
            @include schriftart($layout, 20, 28);
        }

        &__abschnitt {
            @include abstand($layout, margin, alle, 0, 0, 20, 0);
            @include schriftart($layout, 20, 28);
        }
        
        &__angebote {
            @include abstand($layout, padding, alle, 0, 0, 52, 0);
            columns: 2;
            column-gap: groesse($layout, 45);
        }
        
        &__checkbox {
            @include abstand($layout, margin, alle, 0, 18, 0, 0);
            height: groesse($layout, 42);
            width: groesse($layout, 42);
        }
        
        &__checkbox-label {
            @include schriftart($layout, 20, 24);
            padding-top: groesse($layout, 10);
        }

        &__kontaktfelder {
            @include abstand($layout, margin, bottom, 52);
        }

        &__feld {
            @include abstand($layout, margin, alle, 20, 0, 20, 0);
        }

        &__input {
            @include schriftart($layout, 24, 28);
            @include abstand($layout, padding, alle, 0, 10, 3, 10);
            height: groesse($layout, 42);
            
        }

        .fui-type-checkboxes[data-field-handle="newsletter"] {
            @include abstand($layout, margin, top, 52);
        }

        &__bestaetigung {
            @include abstand($layout, padding, alle, 400, 400, 0, 400);
            
            p {
                @include schriftart($layout, 24, 28);
                @include abstand($layout, margin, alle, 28, 0, 28, 0);
            }
        }
        
        &__bestaetigung-schliessen {
            top: groesse($layout, 90);
        }
        
        &__bestaetigung-schliessen-svg {
            height: groesse($layout, 46);
            width: groesse($layout, 46);
        }

        .fui-submit {
            padding-right: groesse($layout, 45);
            background-size: groesse($layout, 30), auto;
        }
    }
}